import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiLayers />,
        title: 'Instant home equity report',
        description: 'See if you can lower your rate, drop mortgage insurance, or pull money out of your home plus more!',
        link:'https://www.qualyonline.com/getreport?id=1'
    },
    {
        icon: <FiUsers />,
        title: 'Qualification estimator',
        description: 'Instantly see an estimate of how much home you could buy with just a few simple inputs.',
        link:'https://www.qualyme.com/'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'More Services',
        description = 'Use the links to get a detailed home equity report, or get an instant estimate on how much you could qualify for.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link} target="#">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
