import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import Particles from 'react-particles-js';
import VisibilitySensor from 'react-visibility-sensor';

class SliderOne extends Component {
    state = {
        animate: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({animate: true});
        }
    }
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner" align="center">
                                <h2 className="title theme-gradient">Qualy Mortgage</h2>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white">
                        <ServiceOne />
                    </div>   
                    {/* end */}
                </div>
            </div>
            <div className="frame-layout__particles">
                <div className="header-btn" align="center">
                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                            <a className="rn-btn" target="_blank" href="https://wclteam.himaxwell.com/loan_applications/new?share_token=2544-a3a5d389-8e74-4fc3-9b6a-c50b241110d9">
                                <span>START LOAN APPLICATION</span>
                            </a>
                        </VisibilitySensor>
                    </div>
                <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 80
                            },
                            "size": {
                                "value": 4
                            }
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            }
                        }
                    }}
                />
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;