import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Business Stratagy',
        description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
    },
    {
        icon: '02',
        title: 'Removing obsticles',
        description: 'Whether you are looking to refinance or purchase a home, we make the process fast and easy.'
    },
    {
        icon: '03',
        title: 'Marketing & Reporting',
        description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="icon">
                        </div>
                        <div className="service service__style--1" align="center">
                            <h4 className="title"></h4>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" align="center">
                        <div className="icon">
                            <img src={`/assets/images/logo/qualylogo.png`} width={120} height={120} alt="Qualy Mortgage"/>
                        </div>
                        <div className="service service__style--1" align="center">
                            <div className="content">
                                <h4 className="title">Removing the obsticles to your mortgage qualification</h4>
                                <p>Ryan Schattner NMLS:1622365 DRE:01791658</p>
                                <p>Cell: <a href="tel:9162254923">916-225-4923</a> <br/>Email: <a href="mailto:ryan@wclteam.com">ryan@wclteam.com</a></p>
                            </div>
                        </div>
                    </div>
                    {/*ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))*/}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;