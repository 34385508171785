import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'We make the user experience our main priority. We want you to have an easy and smooth home buying or refinancing experience so we design our systems to do just that. By levereging technology to automate and speed up processes we can lower the cost of business and pass the savings on to you.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/my_pic_use.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Direct Lending</h3>
                                                <p>We are a corespondent lender, or we can broker your loan. What this means is we loan you our money directly, then sell the loan off. For you this allows us more flexible pricing. If you at other lenders let us know because there is a good chance we can beat their pricing.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Ease of use</h3>
                                                <p>With technology we have easy to use portals, plus deep data available to you to make sure you can make informed decisions and go through the mortgage process in a convenient way.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;